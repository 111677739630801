import {
  FRCWidgetWidgetErrorEvent,
  FriendlyCaptchaSDK,
  WidgetHandle,
} from "@friendlycaptcha/sdk";

const sitekey = "FCMPKP3O8NNFENKJ";
const friendlyCaptchaSDK = new FriendlyCaptchaSDK();

document.addEventListener("DOMContentLoaded", () => {
  // Get all widgets on the page with the class .frc-captcha
  const captchaWidgets: NodeListOf<HTMLDivElement> =
    document.querySelectorAll(".frc-captcha");

  // Iterate all found widget elements and add the generated widget
  captchaWidgets.forEach((widgetElement) => {
    const widget = friendlyCaptchaSDK.createWidget({
      element: widgetElement,
      sitekey,
      startMode: "focus",
    });

    const submitButton: HTMLButtonElement | null | undefined = widgetElement
      .closest("form")
      ?.querySelector("button[type=submit]");
    if (!submitButton)
      return console.error("No submit button found for captcha widget", widget);

    widget.getElement().style.height = "40px"; // Set a lower height for the captcha
    widget.getElement().style.width = "100%"; // Set full width for the captcha

    submitButton.disabled = true; // Disable submit button until captcha is completed
    widget.addEventListener(
      "frc:widget.complete",
      () => (submitButton.disabled = false),
    );
    widget.addEventListener("frc:widget.error", (event) => {
      submitButton.disabled = true;
      logErrorAndResetCaptcha(widget, event);
    });

    widget.addEventListener("frc:widget.expire", () => widget.reset());

    // Reset the captcha when the email is validated
    document.addEventListener("ssi-email-validated", () => {
      widget.reset();
    });
  });
});

// Helpers
function logErrorAndResetCaptcha(
  widget: WidgetHandle,
  event: FRCWidgetWidgetErrorEvent,
) {
  widget.reset();

  const error = {
    location: window.location.href,
    error: event.detail,
  };

  console.error("Captcha error", error);
  fetch("/user/captcha/log", {
    headers: {
      "Content-Type": "application/json",
    },
    method: "POST",
    body: JSON.stringify(error),
  });
}
